<template>
<div id="noviceTutorial">
   <!--头部搜索-->
   <div class="bg-white searchDom">
     <div class="flex align-center justify-end margin-bottom-24 header_search">
        <div class="flex align-center solid margin-right-24 padding-lr-16" style="height:32px;width: 300px;">
          <el-input @input="searKeyword" v-model="sendData.keyword" placeholder="请输入ID查询" suffix-icon="search">
          </el-input>
          <i style="cursor: pointer;" class="el-icon-search" @click="searKeyword"></i>
        </div> 
       <el-button @click="addCategory_" style="height: 32px;width: 68px;padding: 0;color: #FFFFFF;background-color: #4E9F5B;">添加分类</el-button>
       <el-button @click="addVideos" style="height: 32px;width: 68px;padding: 0;color: #4E9F5B !important;border-color: #4E9F5B;">添加视频</el-button>
     </div>
   </div>
  <!-- 搜索列表 -->
  <div class="searchList">
     <div class="card">                        
        <div class="card-block table-border-style">
            <div class="table-responsive">
                <Empty v-show="dataList.length=='0'"/>
                <el-table v-show="dataList.length!='0'"
                   :header-cell-style="{color:'#333333',fontsize:'14px'}" :data="dataList" stripe
                   style="width: 100%">
                   <el-table-column prop="article_id" align="center" label="ID">
                   </el-table-column>
                   <el-table-column prop="article_category_title" align="center" label="视频分类" width="180">
                   </el-table-column>
                   <el-table-column prop="article_cover" align="center" label="视频封面">
                     <template slot-scope="scope">
                       <el-button @click="readInfo(scope.row.article_cover)" style="color: #4E9F5B;" type="text" size="small">查看
                       </el-button>
                     </template>
                   </el-table-column>
                   <el-table-column prop="article_content" align="center" label="视频">
                     <template slot-scope="scope">
                       <el-button @click="watchVideo(scope.row.article_content,scope.row.article_cover)" style="color: #4E9F5B;" type="text" size="small">查看
                       </el-button>
                     </template>
                   </el-table-column>
                   <el-table-column prop="article_release_time" align="center" label="发布时间">
                     <template slot-scope="scope">
                       <span>{{scope.row.article_release_time*1000 | getDate }}</span>
                     </template>
                   </el-table-column>
                   <el-table-column prop="article_state" align="center" label="状态">
                     <template slot-scope="scope">
                       <span>{{scope.row.article_state=='1'?'启用':'禁用'}}</span>
                     </template>
                   </el-table-column>
                   <el-table-column fixed="right" label="操作" align="center">
                     <template slot-scope="scope">
                       <el-button style="color: #4E9F5B !important;" @click="editNovice(scope.row)" type="text" size="small">编辑</el-button>
                       <el-button @click="deleteNovice(scope.row.slider_id)" style="color: #FF1A2E !important;" type="text" size="small">删除</el-button>
                     </template>
                   </el-table-column>
                </el-table>
            </div>
        </div>
      </div>
  </div>
  <!--分页-->
  <div class="pageNum" id="pageNum">
    <Pagination style="margin-top:20px"
    v-show="rows>1"
    :total="rows"
    @change = "getTabList"
    :page.sync= "sendData.page"
    :limit.sync="sendData.limit"/>
  </div>

  <!-- 添加公告弹窗 -->
  <el-dialog id="addCategory" title="添加分类" :visible.sync="showFenLeiModel" @close="closeAddDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
      <div id="head_in" class="flex align-center justify-start margin-top-24">
        <el-input v-model="addCategory_title" placeholder="请输入内容" style="width: 50%;"></el-input>
        <el-button type="success" @click="addType"
          style="height: 32px;background-color: #4E9F5B;padding: 0 10px;margin-left: 16px;">添加
        </el-button>
      </div>
      <div class="margin-top-24">
         <el-table
            :header-cell-style="{background:'#F5F6F7',color:'#333333',fontsize:'14px'}" :data="categoryTypeList" stripe
            style="width: 100%">
            <el-table-column prop="article_category_id" label="分类ID" align="center">
            </el-table-column>
            <el-table-column prop="article_category_title" label="分类名称" align="center">
            </el-table-column>
            <el-table-column prop="article_count" label="视频数量" align="center">
            </el-table-column>
            <el-table-column prop="address" label="操作" align="center">
              <template slot-scope="scope">
                <el-button @click="editCategory(scope.row.article_category_id)" type="text" size="small" style="color: #4E9F5B;">编辑
                </el-button>
                <el-button @click="delCategory(scope.row.article_category_id)" type="text" size="small" style="color: #FF1A2E;">删除
                </el-button>
              </template>
            </el-table-column>
        </el-table>
      </div>
  </el-dialog>
  <!-- 添加食品 -->
  <el-dialog id="addVideoDialog" title="添加视频" :visible.sync="addVideoDialog" @close="closeAddVideoDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
    <el-form style="text-align: left;" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
        label-position="left" class="demo-ruleForm">
        <el-form-item label="分类" prop="article_category_id">
          <el-select @change="changeInfo" v-model="ruleForm.article_category_id" placeholder="请选择">
            <el-option v-for="(item,index) in videoType" :key="index" :label="item.article_category_title" 
            :value="item.article_category_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="视频封面" prop="article_cover">
          <el-upload style="width: 320px;height: 160px;line-height: 160px;" class="avatar-uploader" ref="frontImg"
            :data="upfileData" action="https://oss-campus-print.yihuichuang.com/" :show-file-list="false" :auto-upload="false"
            :with-credentials='false' :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
            :on-change="changeImg">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" style="width:360px;height:180px">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <el-form-item label="视频上传" prop="article_content">

          <el-upload style="width: 320px;height: 160px;line-height: 160px;" class="avatar-uploader" ref="frontVideo"
            action="https://oss-campus-print.yihuichuang.com/" :show-file-list="false" :auto-upload="false" :data="upVideoData"
            :with-credentials='false' :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo"
            :on-change="changeVideo">
            <video v-if="videoForm.showVideoPath !='' && !videoFlag" :src="urlVideo" class="avatar"
              controls="controls" style="width;320px;height:160px">您的浏览器不支持视频播放</video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

        </el-form-item>
        <el-form-item label="状态" prop="article_state">
          <el-switch @change="changeState" v-model="ruleForm.article_state" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="submitAddForm('ruleForm')">提 交</el-button>
    </span>
  </el-dialog>
  <!-- 编辑视频 -->
  <el-dialog id="editVideoDialog" title="编辑" :visible.sync="editVideoDialog" @close="closeEditVideoDialog" :close-on-press-escape="false" 
    :close-on-click-modal="false">
    <el-form style="text-align: left;" :model="ruleForm1" :rules="rules" ref="ruleForm1" label-width="100px"
        label-position="left" class="demo-ruleForm">
        <el-form-item label="分类" prop="article_category_id">
          <el-select @change="changeInfo" v-model="ruleForm1.article_category_id" placeholder="请选择">
            <el-option v-for="(item,index) in videoType" :key="index" :label="item.article_category_title" 
            :value="item.article_category_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="视频封面" prop="article_cover">
          <el-upload style="width: 320px;height: 160px;line-height: 160px;" class="avatar-uploader" ref="frontImg"
            :data="upVideoData" action="https://oss-campus-print.yihuichuang.com/" :show-file-list="false" :auto-upload="false"
            :with-credentials='false' :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
            :on-change="changeImg">
            <img v-if="imageUrl1" :src="imageUrl1" class="avatar" style="width:360px;height:180px">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

        </el-form-item>

        <el-form-item label="视频上传" prop="article_content">
          <el-upload style="width: 320px;height: 160px;line-height: 160px;" class="avatar-uploader" ref="frontImg"
            action="https://oss-campus-print.yihuichuang.com/" :show-file-list="false" :auto-upload="false" :data="upfileData"
            :with-credentials='false' :on-success="handleVideoSuccess" :before-upload="beforeUploadVideo"
            :on-change="changeVideo">
            <video v-if="videoForm1.showVideoPath !='' && !videoFlag" :src="urlVideo1" class="avatar"
              controls="controls" style="width;320px;height:160px">您的浏览器不支持视频播放</video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>

        </el-form-item>
        <el-form-item label="状态" prop="article_state">
          <el-switch @change="changeState" v-model="ruleForm1.article_state" active-value="1" inactive-value="0"></el-switch>
        </el-form-item>
      </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="submitEditForm('ruleForm1')">提 交</el-button>
    </span>
  </el-dialog>
  <!-- 查看轮播图 -->
  <el-dialog id="infoModelBox" title="查看视频封面" :visible.sync="showInfoModel" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body :before-close="closeInfoModel" style="min-height: 320px;border-radius: 8px;padding: 24px !important;margin:0 auto">
      <div  class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left">
        <img :src="imgContent" style="margin-bottom: 16px;margin-right: 10px;width: 100%" >
      </div>
  </el-dialog>

  <!-- 查看视频 -->
  <el-dialog id="imgModelBox" title="视频封面" :visible.sync="showVideoModel" :close-on-press-escape='false'
      :close-on-click-modal='false' append-to-body>

      <div class="padding-16 text-14 text-333 margin-top-24 text-left flex flex-wrap" style="min-height: 225px;">
        <video style="width: 780px;height: 500px;" :src="video.url" class="avatar"
          controls="controls">您的浏览器不支持视频播放</video>
        <!-- <vueMiniPlayer ref="vueMiniPlayer" :video="video" :mutex="true"  @fullscreen="handleFullscreen" /> -->
      </div>
  </el-dialog>
</div>
</template>
<script>
import {article,articleGet,articleAdd,articleDel,articleEdit,articleCategory,
articleCategoryAdd,articleCategoryDel,articleCategoryEdit,fileFind,fileSave} from '@/api/http'
import Pagination from '@/components/pagination'
import SparkMD5 from 'spark-md5'
export default {
    data(){
      return{
        addCategory_title:'',
        /* 分页参数*/
        pages:1, //总页数
        rows:1, //总条数
        dataList: [], //表格数据
        sendTab:{
          page:1,
          limit:10,
          keyword:''
        },
        addVideoDialog:false,
        showVideoModel:false,
        video: {
          url: '',
          cover: '',
          muted: false,
          loop: false,
          preload: "auto",
          poster: "",
          volume: 1,
        },
        //修改轮播图弹窗
        editDialogVisible: false,
        textarea: '',
        currentNoticID:'',
        /* 信息弹窗参数*/
        showFenLeiModel:false,
        imgContent:'',
        ruleForm: {},
        rules:{},
        /* 上传图片组*/
        imageUrl: '', //上传图片
        //上传图片额为参数
        upfileData: {
          key: ''
        },
        /* 上传图片组*/
        imageUrl: '', //上传图片
        imageUrl1: '', //上传图片
        videoType:'',
        editVideoDialog:false,//编辑视频弹出框
        fileMd5: '', //文件md5
        bannerInfo:'',
        //是否显示进度条
        videoUploadPercent: "",
        currentUpload: '',
        videoForm: {
          showVideoPath: ''
        },
        videoForm1: {
          showVideoPath: ''
        },
        upVideoData: {
          key: ''
        },
        sendData: {
          keyword: '',
          page: 1,
          limit: 10,
          article_category_type: 1, //1: 新手教程, 2: 商学院 3：官网文章
          article_category_id: '', //文章类别ID
        },
        categoryTypeList:[],
        showInfoModel:false,
        ruleForm1:{},
        sendEditData:{},
        urlVideo: '',
        urlVideo1: '',
        videoFlag:false
      }
    },
    created(){
        this.getTabList()
        this.getVideoType()
    },
    methods:{
        changeInfo(e){
          console.log(e)
          this.ruleForm1.article_category_id = e
          this.sendEditData.article_category_id = e
        },
        changeState(e){
          console.log(e)
          this.ruleForm1.article_state = e
          this.sendEditData.article_state = e
        },
        getVideoType(){
          articleCategory({
            article_category_type:1
          }).then(res=>{
            console.log('视频分类:',res.data)
            if(res.data.code == '1'){
              this.videoType = res.data.data.list
            }
          })
        },
        addVideos(){
          this.getVideoType()
          this.addVideoDialog = true
        },
        closeAddDialog(){
          this.addVideoDialog = false
        },
        editNovice(info){
          this.videoType.forEach(item=>{
            console.log(item)
            if(item.article_category_title = info.article_category_title){
              info.article_category_id = item.article_category_id
            }
          })
          this.editVideoDialog = true
          this.videoForm1.showVideoPath = '0'
          this.urlVideo1 = info.article_content
          this.imageUrl1 = info.article_cover
          this.ruleForm1 = info
        },
        closeAddVideoDialog(){
          this.addVideoDialog = false
        },
        //查看视频
        watchVideo(url,coverUrl){
          console.log(url,coverUrl)
          this.showVideoModel = true
          this.video.url = url
          this.video.coverUrl = coverUrl
        },
        closeVideoModel(){
          this.showVideoModel = false
        },
        addCategory_(){
          this.showFenLeiModel = true
          this.getArticleCategory()
        },
        //编辑分类名称
        editCategory(id){
          this.$prompt('请输入新的分类名称', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(({
            value
          }) => {
            articleCategoryEdit({article_category_id:id,article_category_title:value}).then(res=>{
              console.log('修改分类名称：',res.data)
              if(res.data.code == '1'){
                this.$message.success('修改成功')
                this.getArticleCategory()
              }else{
                this.$message.success(res.info)
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '取消输入'
            });
          });
        },
        //删除分类
        delCategory(id){
          this.$confirm('此操作将永久删除该分类, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            articleCategoryDel({article_category_id: id}).then(res=>{
              console.log('删除当前分类名称：',res.data)
              if(res.data.code == '1'){
                this.$message.success('删除成功')
                this.getArticleCategory()
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        },
        //添加分类
        addType(){
          if(this.addCategory_title == ''){
            return false
          }
          articleCategoryAdd({
           article_category_type: 1,
           article_category_title: this.addCategory_title}).then(res=>{
              console.log('添加分类：',res.data)
              if(res.data.code == '1'){
                this.$message.success('添加成功')
                this.showFenLeiModel = false
                this.getTabList()
              }else{
                this.$message.error(res.info)
              }
          })
        },
        //获取分类信息
        getArticleCategory(){
          articleCategory({article_category_type: 1}).then(res=>{
            console.log('分类列表：',res.data)
            let {list}=res.data.data
            if(res.data.code == '1' && list){
              // this.rows = count
              this.categoryTypeList = list
            }else if(res.data.code=='1'&&res.data.data.csv_file){
              window.open(res.data.data.csv_file)
              // this.sendData1.limit=this.rows
            }

          })
        }, 
        closeEditDialog(){
          this.editDialogVisible = true
        },
        //文件上传成功之后的处理
        handleAvatarSuccess(res,file){
          this.save_file()
        },
        //上传文件之前的处理
        beforeAvatarUpload(file){
          console.log('上传前', file)
          let date = this.util.formatDate(new Date())
          let date_ = this.util.formatTime(new Date()).replace(/,/g, "")
          let randnum = (Math.random() * 9999999999).toFixed(0)
          let fileName = date_ + randnum
          this.upfileData.key = 'upload/xcx/' + date + '/' + fileName
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
          }
          return isLt2M
        },
        /* 上传成功*/
        handleVideoSuccess(res, file) { //获取上传图片地址
           console.log(res)
           //保存视频
           fileSave({
             file_uri: this.upVideoData.key
           }).then(res => {
             console.log('保存视频', res)
             if (res.code == '1') {
               this.ruleForm.article_content = res.data.file_id //文件id
               this.ruleForm1.article_content = res.data.file_id //文件id--编辑
               this.sendEditData.article_content = res.data.file_id //文件id--编辑
               this.videoForm.showVideoPath = '2'
               this.videoForm1.showVideoPath = '2'
             }
           })
           this.videoFlag = false;
           this.videoUploadPercent = 0;
        },
        //上传视频之前之前的处理
        beforeUploadVideo(file){
          console.log('上传前', file)
          let date = this.util.formatDate(new Date())
          let date_ = this.util.formatTime(new Date()).replace(/,/g, "")
          let randnum = (Math.random() * 9999999999).toFixed(0)
          let fileName = date_ + randnum
          this.upVideoData.key = 'upload/xcx/' + date + '/' + fileName
          const isLt10M = file.size / 1024 / 1024 < 10;
          if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb'].indexOf(file.type) == -1) {
            this.$message.error('请上传正确的视频格式');
            return false;
          }
        },
        /* 上传进度*/
        uploadVideoProcess(event, file, fileList) {
          this.videoFlag = true;
          this.videoUploadPercent = Number(file.percentage.toFixed(0));
        },
        //文件上传成功，保存文件
        save_file(file_uri){
          fileSave({file_uri:this.upfileData.key}).then(res=>{
            console.log('保存视频封面：',res.data)
            if(res.data.code == '1'){
              this.ruleForm.article_cover = res.data.data.file_id
              this.ruleForm1.article_cover = res.data.data.file_id //文件id--编辑
              this.sendEditData.article_cover = res.data.data.file_id //文件id--编辑
            }
          })
        },
        //选择视频
        changeVideo(file){
          console.log(file)
          this.urlVideo = URL.createObjectURL(file.raw)
          this.urlVideo1 = URL.createObjectURL(file.raw)
          this.currentUpload = '2'
          this.upfileImg(file)
        },
        //选择重新上传轮播图文件
        changeImg(file){
          console.log(file)
          this.imageUrl = URL.createObjectURL(file.raw)
          this.currentUpload = '1'
          //上传文件
          this.upfileImg(file)
        },
        //编辑轮播图重新选择图片再上传提交
        submitEditForm(formName){
          this.$refs[formName].validate((valid) => {
          if (valid) {
            this.sendEdit(formName)
          } else {
            console.log('error submit!!');
            return false;
          }
        });
        },
        sendEdit(formName){
          this.sendEditData.article_id = this.ruleForm1.article_id
          articleEdit(this.sendEditData).then(res=>{
            console.log('编辑视频',res.data)
            if(res.data.code == '1'){
              this.$message.success('编辑成功')
              this.$refs[formName].resetFields();
              this.ruleForm1.article_content = ''
              this.sendEditData = {}
              this.videoForm.showVideoPath = ''
              this.imageUrl = ''
              this.urlVideo = ''
              this.editVideoDialog = false
              this.getTabList()
            }else{
              this.$message.error(res.info)
            }
          })
        },  
        //搜索关键字
        searKeyword(){
          // this.getTabList()
          console.log(this.sendData.keyword)
          if(!this.sendData.keyword) return this.getTabList();
          // return this.dataList.filter(v=>{
          //   console.log(v)
          //   return v.coupon_title.includes(this.sendData.keyword);
          // })
          var _this = this
          var newItems = []
          this.dataList.map(function(item){
            // console.log(item)
            if(!item.article_id.search(_this.sendData.keyword)){
              console.log(item)
              newItems.push(item)
            }
          })
          // return newItems
          console.log(newItems)
          this.dataList = newItems
        },
        closeEditVideoDialog(){
          this.editVideoDialog = false
        },
        /* 计算文件md5*/
        upfileImg(file) {
          const _this = this
          var fileReader = new FileReader();
          //此处打印file可看到file下的raw为文件属性
          var dataFile = file.raw;
          let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
          var spark = new SparkMD5.ArrayBuffer();
          //获取文件二进制数据
          fileReader.readAsArrayBuffer(dataFile)
          //异步执行函数
          fileReader.onload = function(e) {
            spark.append(e.target.result);
            var md5 = spark.end()
            console.log('MD5', md5)
            /* 查找文件是否存在*/
            _this.find_file(md5)
          }
        },

        /* 检查文件是否存在*/
        find_file(sparkEnd) {
          let that = this
          fileFind({
            file_md5: sparkEnd
          }).then(res => {
            console.log('检查', res)
            if (res.data.code == '1') { //文件存在，无需上传
              if(that.currentUpload = '1'){
                that.ruleForm.article_cover = res.data.data.file_id //文件id
                that.ruleForm1.article_cover = res.data.data.file_id //文件id
                that.sendEditData.article_cover = res.data.data.file_id //文件id
              }else{
                that.videoForm.showVideoPath = '2'
                that.ruleForm.article_cover = res.data.data.file_id //文件id
                that.ruleForm1.article_cover = res.data.data.file_id //文件id
                that.sendEditData.article_cover = res.data.data.file_id //文件id
              }
            } else { //文件不存在，上传文件
              // this.upload('frontImg')
              console.log('可以重新上传文件')
              if(that.currentUpload == '1'){
                that.$refs['frontImg'].submit()
              }else{
                that.$refs['frontVideo'].submit()
              }
            }
          })
        },
        //查看意见描述
        readInfo(info){
          this.showInfoModel = true
          this.imgContent = info
        },
        //获取公共列表
        getTabList(){
            let that = this
            let tabDat = {}
            for (let item in this.sendData) {
              if (that.sendData[item] != '' && that.sendData[item] != '999') {
                tabDat[item] = that.sendData[item]
              }
            }
            article(tabDat).then(res=>{
                console.log('新手教程管理：',res.data)
                let {list,page,count,limit}=res.data.data
                console.log(res.data.code,list,page,count,limit)
                if(res.data.code == '1' && list){
                  this.rows = count
                  this.dataList = list
                }else if(res.data.code=='1'&&res.data.data.csv_file){
                  window.open(res.data.data.csv_file)
                  this.sendData.limit=this.rows
                }
            })
        },
        //提交
        submitAddForm(formName){
          this.$refs[formName].validate((valid)=>{
            if(valid){
              //发送添加banner的方法
              this.sendRequestAddVideo(formName)
            }else{
              console.log('error submit')
              return false
            }
          })
        },
        sendRequestAddVideo(formName){
          articleAdd(this.ruleForm).then(res=>{
            console.log('发布视频',res.data)
            if(res.data.code == '1'){
              this.$message.success('发布成功')
              this.$refs[formName].resetFields();
              this.ruleForm.article_content = ''
              this.videoForm.showVideoPath = ''
              this.imageUrl = ''
              this.urlVideo = ''
              this.addVideoDialog = false
              this.getTabList()
            }else{
              this.$message.error(res.info)
            }
          })
        },
        submitEditForm(formName){
          this.$refs[formName].validate((valid)=>{
            if(valid){
              this.sendEdit(formName)
            }else{
              console.log('error submit')
              return false
            }
          })
        },
        //上传视频成功
        handleVideoSuccess(res,file){
          console.log(res)
          //保存视频
          fileSave({file_uri:this.upVideoData.key}).then(res=>{
            console.log('保存视频：',res)
            if(res.data.code == '1'){

            }
          })
        },
        sendEdit(formName){
          this.sendEditData.article_id = this.ruleForm1.article_id
          articleEdit(this.sendEditData).then(res=>{
            console.log('修改',res.data)
            if(res.data.code == '1'){
              this.$message.success('编辑成功')
              this.$refs[formName].resetFields()
              this.ruleForm1.article_content = ''
              this.sendEditData = {}
              this.videoForm.showVideoPath = ''
              this.imageUrl = this.urlVideo = ''
              this.editVideoDialog = false
              this.getTabList()
            }else{
              this.$message.error(res.info)
            }
          })
        },
        //导出表格
        exportData(){
          this.sendTab.limit = 9999
          this.sendTab.page = 1
          this.getNoticeList()
        },
        /* 附件*/
        showpicture(arr) {
          this.showImgModel = true
          this.imgContent = arr
        },
        closeImgModel() {
          this.showImgModel = false
        },
        closeInfoModel() {
          this.showInfoModel = false
        },
        //退款成功，刷新列表
        changeList(){
            this.getFinanceRefund()
        },
        //关闭编辑公告弹窗
        closeEditModel(){
          this.editDialogVisible = false
        },
        closeAddDialog(){
          this.showFenLeiModel = false
        },
        //导出
        exportTab(){
          this.sendTab.limit = 9999
          this.sendTab.page = 1
          this.getNoticeList()
        },
        deleteNovice(id) {
          this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            articleDel({article_id:id}).then(res=>{
              if(res.data.code == '1'){
                this.$message.success('删除成功')
                this.getTabList()
              }else{
                this.$message.success(res.info)
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
      },
    },
    components:{
        Pagination
    }
}
</script>
<style>
#noviceTutorial .header_search .el-input__inner {
    height: 20px !important;
    border: none !important;
    padding: 0 !important;
  }
  #addCategory .el-dialog{
    width: 780px;
    margin: 0 auto;
  }
  #addCategory .el-dialog__body{
    padding: 20px;
    height: 600px;
  }
  #noviceTutorial .avatar {
    width: 320px;
    height: 160px;
    display: block;
}
#noviceTutorial .el-dialog__footer {
    text-align: center !important;
}
#noviceTutorial .el-dialog__footer button{
    width: 260px;
    height: 40px;
    background: #4E9F5B;
    border-radius: 4px;
    color: white;
}
</style>